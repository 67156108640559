import React, { useState, useEffect, useCallback, useRef } from "react";
import "./App.css";
import "./normalize.css";
import moment from "moment";

import useFirebaseAuthentication, { signOut, Sign } from "./Authentication.js";
import Tasks from "./Tasks";
import Notes from "./Notes";

import { db } from "./firebase";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleLeft,
  faAngleRight,
  faTimes,
  faArrowDown,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";

import useOutsideAlerter from "./OutsideAlerter";
import TextareaAutosize from "react-autosize-textarea/lib";

export const UserContext = React.createContext(null);

export const convertDayToWord = (day) => {
  const today = moment();
  if (day.isSame(moment(today), "day")) {
    return "today";
  }
  if (day.isSame(moment(today).add(1, "days"), "day")) {
    return "tomorrow";
  }
  if (day.isSame(moment(today).subtract(1, "days"), "day")) {
    return "yesterday";
  }
  return day.format("MMM Do");
};

function App() {
  // USER
  const user = useFirebaseAuthentication();

  const [recurr, setRecurr] = useState([]);
  const [recurrOrder, setRecurrOrder] = useState(null);
  // const [recurrOrdered, setRecurrOrdered] = useState([]);
  const [recurrInc, setRecurrInc] = useState([]);
  const [recurrIncOrdered, setRecurrIncOrdered] = useState([]);
  const [recurrComp, setRecurrComp] = useState([]);
  const [recurrCompIds, setRecurrCompIds] = useState([]);

  const [tasksTodo, setTasksTodo] = useState([]);
  const [tasksCompleted, setTasksCompleted] = useState([]);
  const [taskOrder, setTaskOrder] = useState(null);
  const [tasksTodoOrdered, setTasksTodoOrdered] = useState([]);

  const [persistTodos, setPersistTodos] = useState([]);
  const [persistOrderedTodos, setPersistOrderedTodos] = useState([]);
  const [persistCompleted, setPersistCompleted] = useState([]);
  const [persistTodosOrder, setPersistTodosOrder] = useState(null);

  const [clearNotes, setClearNotes] = useState(0);
  const signOutThenFn = () => {
    setRecurr([]);
    setRecurrOrder([]);
    setRecurrInc([]);
    setRecurrIncOrdered([]);
    setRecurrComp([]);
    setRecurrCompIds([]);
    setTasksTodo([]);
    setTasksCompleted([]);
    setTaskOrder([]);
    setTasksTodoOrdered([]);
    setPersistTodos([]);
    setPersistOrderedTodos([]);
    setPersistCompleted([]);
    setPersistTodosOrder([]);

    // dirty solution for clearing notes. will change key, causing remount
    setClearNotes(clearNotes + 1);
  };

  const [day, setDay] = useState(moment());

  const orderFunc = useCallback((a, b, list) => {
    // if one of the elements not found, give other element precedence
    const indexA = list.indexOf(a);
    const indexB = list.indexOf(b);
    if (indexA === -1 && indexB === -1) {
      return 0;
    } else if (indexA === -1) {
      return 1;
    } else if (indexB === -1) {
      return -1;
    }
    return indexA - indexB;
  }, []);

  const orderListener = useCallback(
    (collection, setOrderList) => {
      const unsub = db
        .collection("users")
        .doc(user.uid)
        .collection(collection)
        .doc("order")
        .onSnapshot((doc) => {
          if (doc.exists) {
            setOrderList(doc.data().id_list);
          } else {
            setOrderList(null);
          }
        });
      return () => unsub();
    },
    [user]
  );

  // LISTENER FOR ORDER
  useEffect(() => {
    if (!user) {
      return;
    }
    return orderListener(day.format("YYYY-MM-DD"), setTaskOrder);
  }, [user, day, orderListener]);

  useEffect(() => {
    if (!user) {
      return;
    }
    return orderListener("persistent-tasks", setPersistTodosOrder);
  }, [user, orderListener]);

  useEffect(() => {
    if (!user) {
      return;
    }
    return orderListener("recurring", setRecurrOrder);
  }, [user, orderListener]);

  const taskListener = useCallback(
    (collection, completed, setList) => {
      const unsub1 = db
        .collection("users")
        .doc(user.uid)
        .collection(collection)
        .where("completed", "==", completed)
        .onSnapshot((snapshot) => {
          setList(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
        });
      return () => unsub1();
    },
    [user]
  );

  // LISTENERS for TASKS (tasks/persistent x todo/completed)
  useEffect(() => {
    if (!user) {
      return;
    }
    return taskListener(day.format("YYYY-MM-DD"), false, setTasksTodo);
  }, [user, day, taskListener]);

  useEffect(() => {
    if (!user) {
      return;
    }
    return taskListener(day.format("YYYY-MM-DD"), true, setTasksCompleted);
  }, [user, day, taskListener]);

  useEffect(() => {
    if (!user) {
      return;
    }
    return taskListener("persistent-tasks", false, setPersistTodos);
  }, [user, taskListener]);

  useEffect(() => {
    if (!user) {
      return;
    }
    return taskListener("persistent-tasks", true, setPersistCompleted);
  }, [user, taskListener]);

  useEffect(() => {
    if (!user) {
      return;
    }
    const unsub = db
      .collection("users")
      .doc(user.uid)
      .collection("recurring")
      .orderBy("timestamp") // order by is just to filter out notes doc
      .onSnapshot((snapshot) => {
        setRecurr(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
      });
    return () => unsub();
  }, [user]);

  // IF TASKTODO OR PERSIST CHANGES, UPDATE THE 'ORDERED' STATES
  const updateOrdered = useCallback(
    (tasks, orderList, setOrdered) => {
      // If go to day with no tasks made yet, don't use old data
      if (tasks.length === 0) {
        setOrdered([]);
      }
      if (tasks && orderList) {
        const prev = [...tasks];
        setOrdered(prev.sort((a, b) => orderFunc(a.id, b.id, orderList)));
      }
    },
    [orderFunc]
  );

  useEffect(() => {
    updateOrdered(tasksTodo, taskOrder, setTasksTodoOrdered);
  }, [taskOrder, tasksTodo, updateOrdered]);

  useEffect(() => {
    updateOrdered(persistTodos, persistTodosOrder, setPersistOrderedTodos);
  }, [persistTodosOrder, persistTodos, updateOrdered]);

  useEffect(() => {
    if (!user) {
      return;
    }
    db.collection("users")
      .doc(user.uid)
      .collection(day.format("YYYY-MM-DD"))
      .doc("recurring-completed")
      .onSnapshot((doc) => {
        if (doc.exists) {
          const completedIds = doc.data().id_list;
          setRecurrCompIds(completedIds);
        } else {
          setRecurrCompIds([]);
        }
      });
  }, [user, day]);

  useEffect(() => {
    const inc = [];
    const comp = [];
    for (const task of recurr) {
      if (recurrCompIds.includes(task.id)) {
        comp.push(task);
      } else {
        inc.push(task);
      }
    }
    setRecurrInc(inc);
    setRecurrComp(comp);
  }, [recurr, recurrCompIds]);

  useEffect(() => {
    if (recurrInc.length === 0) {
      setRecurrIncOrdered([]);
    }
    if (recurrInc && recurrOrder) {
      const prev = [...recurrInc];
      setRecurrIncOrdered(
        prev.sort((a, b) => orderFunc(a.id, b.id, recurrOrder))
      );
    }
  }, [recurrOrder, recurrInc, orderFunc]);

  const goLeft = () => {
    setDay(moment(day).subtract(1, "days"));
  };
  const goRight = () => {
    setDay(moment(day).add(1, "days"));
  };
  const goToday = () => {
    setDay(moment());
  };

  // SIGN IN / UP MODAL
  const [openModal, setOpenModal] = useState(null);
  const openLogIn = () => setOpenModal("login");
  const openSignup = () => setOpenModal("signup");
  const closeModal = () => setOpenModal(null);
  const [submittedSign, setSubmittedSign] = useState(false);

  useEffect(() => {
    if (user && submittedSign) {
      setOpenModal(false);
    }
  }, [user, submittedSign]);

  const [showWarning, setShowWarning] = useState(false);

  useEffect(() => {
    if (!user) {
      return;
    }
    if (user.isAnonymous) {
      const unsub = db
        .collection("users")
        .doc(user.uid)
        .onSnapshot((doc) => {
          console.log("user collecion snapshot");
          if (doc.exists) {
            setShowWarning(doc.data().settings_clear_warning);
          } else {
            setShowWarning(true);
          }
        });
      return () => unsub();
    }
  }, [user]);

  const clearWarning = useCallback(() => {
    if (!user) {
      return;
    }
    db.collection("users")
      .doc(user.uid)
      .set({ settings_clear_warning: false })
      .catch((error) => {
        console.log(error);
      });
  }, [user]);

  /*
  result: {
    
      YYYY-MM-DD: [
    {
      data...
    } ],
    YYY...
  
  }

  */

  const downloadJSON = (jsonData, title) => {
    const fileData = JSON.stringify(jsonData, null, 1);
    const blob = new Blob([fileData], { type: "text/plain" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.download = `${title}.txt`;
    link.href = url;
    link.click();
  };

  const [showDownloadLink, setShowDownloadLink] = useState(false);

  const downloadData = useCallback(() => {
    if (!user) {
      return;
    }
    const numDays = 14;
    let result = {};

    result["daily goals"] = {};
    // DAILY TASKS
    const promise1 = new Promise((resolve, reject) => {
      for (let i = 0; i < numDays; i++) {
        let day = moment().subtract(i, "days").format("YYYY-MM-DD");
        db.collection("users")
          .doc(user.uid)
          .collection(day)
          .get()
          .then((querySnapshot) => {
            const a = [];
            querySnapshot.forEach((doc) => {
              if (doc.data().hasOwnProperty("content")) {
                let { timestamp, ...data } = doc.data();
                a.push(data);
              }
            });
            if (a.length > 0) {
              result["daily goals"][day] = a;
            }
            resolve();
          });
      }
    });

    // recurring
    const promise4 = new Promise((resolve, reject) => {
      db.collection("users")
        .doc(user.uid)
        .collection("recurring")
        .get()
        .then((querySnapshot) => {
          const a = [];
          querySnapshot.forEach((doc) => {
            if (doc.data().hasOwnProperty("content")) {
              let { timestamp, completed, ...data } = doc.data();
              a.push(data);
            }
          });
          result["recurring"] = a;
          resolve();
        });
    });

    // notes
    const promise2 = new Promise((resolve, reject) => {
      db.collection("users")
        .doc(user.uid)
        .collection("notes")
        .get()
        .then((querySnapshot) => {
          const a = {};
          querySnapshot.forEach((doc) => {
            a[doc.id] = doc.data();
          });
          result["notes"] = a;
          resolve();
        });
    });

    // persistent (planning)
    const promise3 = new Promise((resolve, reject) => {
      db.collection("users")
        .doc(user.uid)
        .collection("persistent-tasks")
        .get()
        .then((querySnapshot) => {
          const a = [];
          querySnapshot.forEach((doc) => {
            if (doc.data().hasOwnProperty("content")) {
              let { timestamp, ...data } = doc.data();
              a.push(data);
            }
          });
          result["planning"] = a;
          resolve();
        });
    });

    Promise.all([promise1, promise2, promise3, promise4]).then(() => {
      downloadJSON(
        result,
        "DailyTasks " +
          moment()
            .subtract(numDays - 1, "days")
            .format("MMM DD, YYYY") +
          " to " +
          moment().format("MMM DD, YYYY")
      );
    });
  }, [user]);

  const downloadLinkRef = useRef(null);
  useOutsideAlerter(downloadLinkRef, () => setShowDownloadLink(false));

  const nameInputRef = useRef(null);
  const [showEditNameInput, setShowEditNameInput] = useState(false);
  const [editedUsername, setEditedUsername] = useState("");
  const [displayName, setDisplayName] = useState("");
  useEffect(() => {
    if (user) {
      setDisplayName(user.displayName);
    }
  }, [user]);
  const updateUsername = (name) => {
    if (!user) {
      return;
    }
    if (user.displayName !== name) {
      user.updateProfile({ displayName: name });
      setDisplayName(name);
    }
    setShowEditNameInput(false);
  };

  // const [showSignOut, setShowSignOut] = useState(false);
  return (
    <UserContext.Provider value={user}>
      <div className="App">
        <header>
          <div className="right">
            {user && (
              <div className="download-container" ref={downloadLinkRef}>
                {showDownloadLink && (
                  <div className="download-link">
                    <a onClick={downloadData}>Download</a> data for previous 2
                    weeks
                  </div>
                )}
                <button
                  className="download-button"
                  onClick={() => setShowDownloadLink(!showDownloadLink)}
                >
                  <FontAwesomeIcon icon={faArrowDown} />
                </button>
              </div>
            )}
            {user && !user.isAnonymous ? (
              <>
                <button
                  className="sign-out-button"
                  onClick={() => signOut(signOutThenFn)}
                >
                  Sign out
                </button>
                {showEditNameInput ? (
                  <>
                    <NameInput
                      value={editedUsername}
                      onChange={(e) => setEditedUsername(e.target.value)}
                    />

                    <button
                      className="download-button save"
                      onClick={() => {
                        updateUsername(editedUsername);
                      }}
                    >
                      <FontAwesomeIcon icon={faCheck} />
                    </button>
                  </>
                ) : (
                  <button
                    onClick={() => {
                      setEditedUsername(user && user.displayName);
                      setShowEditNameInput(true);
                    }}
                    className="user-button logged-in"
                    title={user && user.email}
                  >
                    {displayName}
                  </button>
                )}
              </>
            ) : (
              <>
                <button className="user-button" onClick={openSignup}>
                  Sign up
                </button>
                <button className="user-button" onClick={openLogIn}>
                  Log in
                </button>
              </>
            )}
          </div>
        </header>
        <main>
          <div className="page-container">
            <div className="daily-container">
              <div className="main-header">
                <h1 className="title">{convertDayToWord(day)}'s goals</h1>
                <div className="day-nav">
                  <span className="day-nav-date">
                    {day.format("ddd, MMM Do, YYYY")}
                  </span>
                  <button onClick={goLeft} title="Previous day">
                    <FontAwesomeIcon icon={faAngleLeft} />
                  </button>
                  <button onClick={goRight} title="Next day">
                    <FontAwesomeIcon icon={faAngleRight} />
                  </button>
                  <button id="today-button" onClick={goToday}>
                    Today
                  </button>
                </div>
              </div>
              {/* DAILY TASKS */}
              <Tasks
                tasks={tasksTodoOrdered}
                tasksCompleted={tasksCompleted}
                collection={day.format("YYYY-MM-DD")}
                day={day.format("YYYY-MM-DD")}
                taskMessage={`Add a task for ${convertDayToWord(day)}`}
              />

              <h2 className="recurring-title">Recurring</h2>
              <Tasks
                tasks={recurrIncOrdered}
                collection={"recurring"}
                tasksCompleted={recurrComp}
                day={day.format("YYYY-MM-DD")}
                taskMessage="Add a recurring task"
              />

              <Notes day={day} key={day.format("YYYY-MM-DD") + clearNotes} />
            </div>

            <div className="persistent-container">
              <h2 className="persistent-tasks-title">Planning</h2>
              <Tasks
                tasks={persistOrderedTodos}
                tasksCompleted={persistCompleted}
                collection={"persistent-tasks"}
                taskMessage="Add a task"
              />
            </div>
          </div>

          {user && user.isAnonymous && showWarning && (
            <div className="warning-container">
              <div className="warning-content">
                <p>
                  <span className="bold">
                    You can use this app without an account!
                  </span>{" "}
                  But without one, changes are erased if browser cookies are
                  deleted.
                </p>
                <p>
                  <a onClick={openSignup}>Sign up</a> at any time to edit across
                  all your devices. Edits you've made on the device you're
                  signing up with will be merged into your new account!
                </p>
                <button className="edit-buttons" onClick={clearWarning}>
                  <FontAwesomeIcon icon={faTimes} />
                </button>
              </div>
            </div>
          )}
        </main>
      </div>
      {openModal && (
        <Sign
          type={openModal}
          linkTo={openModal === "login" ? openSignup : openLogIn}
          onExit={closeModal}
          onSubmit={() => setSubmittedSign(true)}
        />
      )}
    </UserContext.Provider>
  );
}

function NameInput({ value, onChange }) {
  const nameInputRef = useRef(null);
  useEffect(() => {
    if (nameInputRef && nameInputRef.current) {
      nameInputRef.current.focus();
    }
  }, []);

  return (
    <input
      type="text"
      className="user-button logged-in"
      onChange={onChange}
      value={value}
      style={{ width: "200px" }}
      ref={nameInputRef}
    />
  );
}

export default App;
