import React, { useState, useEffect, useContext } from "react";
import firebase from "firebase";
import { UserContext } from "./App";
import "./Authentication.css";
import GoogleIcon from "./GoogleIcon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

function useFirebaseAuthentication() {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unlisten = firebase.auth().onAuthStateChanged((user) => {
      console.log("auth state changed");
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
    });

    return unlisten;
  });

  return user;
}

export const signOut = (thenFn) =>
  firebase
    .auth()
    .signOut()
    .then(() => {
      thenFn();
    });

export const signInWithGoogle = () => {
  let googleProvider = new firebase.auth.GoogleAuthProvider();
  firebase
    .auth()
    .signInWithPopup(googleProvider)
    .then(function (result) {
      // This gives you a Google Access Token. You can use it to access the Google API.
      // var token = result.credential.accessToken;
      // The signed-in user info.
      // var user = result.user;
      // ...
      // if (isAnon) {
      //   let credential = firebase.auth.GoogleAuthProvider.credential(
      //     user.getAuthResponse().id_token
      //   );
      //   firebase.auth.currentUser
      //     .linkWithCredential(credential)
      //     .then(function (usercred) {
      //       var user = usercred.user;
      //       console.log("Anonymous account successfully upgraded", user);
      //     })
      //     .catch(function (error) {
      //       console.log("Error upgrading anonymous account", error);
      //     });
      // }
    })
    .catch(function (error) {
      // Handle Errors here.
      // var errorCode = error.code;
      // var errorMessage = error.message;
      // The email of the user's account used.
      // var email = error.email;
      // The firebase.auth.AuthCredential type that was used.
      // var credential = error.credential;
      // ...
    });
};

export const logIn = (username, password) => {
  return new Promise((resolve, reject) => {
    firebase
      .auth()
      .signInWithEmailAndPassword(username, password)
      .then(() => resolve())
      .catch((error) => reject(error));
  });
};

export const createUser = (name, username, password, setNewUser) => {
  let user = null;
  return new Promise((resolve, reject) => {
    firebase
      .auth()
      .createUserWithEmailAndPassword(username, password)
      .then(() => {
        setNewUser(1);
        user = firebase.auth().currentUser;
        user.sendEmailVerification();
      })
      .then(() => {
        user.updateProfile({
          displayName: name,
        });
      })
      .catch((error) => reject(error));
  });
};

export function Sign({ type, linkTo, onExit, onSubmit }) {
  const user = useContext(UserContext);

  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [newUser, setNewUser] = useState(0);

  const onGoogleClick = () => {
    if (user && user.isAnonymous && type === "signup") {
      // Authenticate with the first user then save the currentUser to a local variable
      let anonUser = firebase.auth().currentUser;

      // Authenticate with a second method and get a credential
      let provider = new firebase.auth.GoogleAuthProvider();

      console.log("anon user signing up with google");

      anonUser
        .linkWithPopup(provider)
        .then((result) => {
          result.user.updateProfile({
            displayName: result.user.email,
          });
          setNewUser(2);
        })
        .catch((error) => {
          if (error.code === "auth/credential-already-in-use") {
            setError(
              <p>
                That Google Account is already associated with an existing
                DailyGoal user. To sign in using that Google Account, go to the{" "}
                <a onClick={linkTo}>Log in</a> page and click 'Log in with
                Google'.
              </p>
            );
          } else {
            setError(error.message);
          }
        });
    } else {
      signInWithGoogle();
      onSubmit();
    }
  };

  const handleSubmit = (e) => {
    if (type === "login") {
      logIn(username, password).catch((error) => setError(error.message));
      onSubmit();
    } else {
      if (user && user.isAnonymous) {
        var credential = firebase.auth.EmailAuthProvider.credential(
          username,
          password
        );
        console.log("anon user");
        firebase
          .auth()
          .currentUser.linkWithCredential(credential)
          .then((usercred) => {
            console.log("Anonymous account successfully upgraded");
            usercred.user.sendEmailVerification();
            usercred.user.updateProfile({
              displayName: name,
            });
            console.log("sent email and updated name");
            setNewUser(1);
          })
          .catch((error) => {
            setError(error.message);
          });
      } else {
        createUser(name, username, password, setNewUser).catch((error) =>
          setError(error.message)
        );
      }
    }
    e.preventDefault();
  };

  const newUserContent = (
    <div className="new-user-content">
      <h1>
        <span className="welcome">Welcome!</span> You're ready to go.
      </h1>
      <p>To verify your new account please check your email. </p>
      <p>
        We've sent you one with the subject line:{" "}
        <span className="subject-line">
          Welcome to DailyGoal! Verify your email.
        </span>
      </p>
      <button className="user-button" onClick={onExit}>
        Done
      </button>
    </div>
  );

  const title = type === "login" ? "Log in" : "Sign up";
  const footer =
    type === "login" ? (
      <p>
        Don't have an account? <button onClick={linkTo}>Sign Up</button>
      </p>
    ) : (
      <p>
        Already have an account? <button onClick={linkTo}>Log In</button>
      </p>
    );

  return (
    <div className="modal-container" onClick={onExit}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        {newUser > 0 ? (
          <div className="new-user-content">
            <h1>
              <span className="welcome">Welcome!</span> You're ready to go.
            </h1>
            {newUser === 1 && (
              <>
                <p>To verify your new account please check your email. </p>
                <p>
                  We've sent you one with the subject line:{" "}
                  <span className="subject-line">
                    Welcome to DailyGoal! Verify your email.
                  </span>
                </p>
              </>
            )}
            <button className="user-button" onClick={onExit}>
              Done
            </button>
          </div>
        ) : (
          <>
            {user && !user.isAnonymous && (
              <div className="sign-in-with">
                Currently signed in as {user.displayName}
              </div>
            )}
            <h1>{title}</h1>
            <div className="sign-in-with">
              <button
                className="google-sign-in background-button"
                onClick={onGoogleClick}
              >
                <div className="google-icon">{GoogleIcon}</div>
                {type === "login" ? "Log in" : "Sign up"} with Google
              </button>
            </div>
            <div className="or-divider">
              <span className="or-divider-line"></span>
              <p className="or-divider-text">or</p>
              <span className="or-divider-line"></span>
            </div>
            <form onSubmit={handleSubmit}>
              {type !== "login" && (
                <>
                  <label htmlFor="name">Name</label>
                  <input
                    id="name"
                    onChange={(e) => setName(e.target.value)}
                    value={name}
                    required
                  ></input>
                </>
              )}
              <label htmlFor="username">Email address</label>
              <input
                id="username"
                onChange={(e) => setUsername(e.target.value)}
                value={username}
                required
              ></input>
              <label htmlFor="password">Password</label>
              <input
                id="password"
                type="password"
                onChange={(e) => setPassword(e.target.value)}
                value={password}
                required
              ></input>
              <input
                className="user-button"
                type="submit"
                value={title}
              ></input>
            </form>
            <div className="footer">
              {error && <div className="error">Error: {error}</div>}
              {footer}
            </div>
            <div className="exit-button" onClick={onExit}>
              <FontAwesomeIcon icon={faTimes} />
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default useFirebaseAuthentication;
