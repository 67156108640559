import React, { useContext, useState, useEffect, useCallback } from "react";
import { UserContext, convertDayToWord } from "./App";
import firebase from "firebase";
import { db } from "./firebase";
import TextareaAutosize from "react-autosize-textarea";
import useDebounce from "./debounce";
import "./Notes.css";

function Notes({ day }) {
  const user = useContext(UserContext);

  const [showNotes, setShowNotes] = useState(false);

  // TODO: make notes an object so can add add. state like heart :)

  useEffect(() => {
    if (!user) {
      setShowNotes(true);
      return;
    }
    const docRef = db
      .collection("users")
      .doc(user.uid)
      .collection("notes")
      .doc(day.format("YYYY-MM-DD"));

    docRef.get().then((doc) => {
      setShowNotes(true);
      if (doc.exists) {
        setNewNotes(doc.data().notes);
      } else {
        setNewNotes(null);
      }
    });
  }, [user, day]);

  const updateNotes = useCallback(
    (notes) => {
      if (!user) {
        return;
      }
      const docRef = db
        .collection("users")
        .doc(user.uid)
        .collection("notes")
        .doc(day.format("YYYY-MM-DD"));

      docRef.get().then((doc) => {
        if (doc.exists) {
          docRef.update({ notes: notes });
        } else {
          docRef.set({
            notes: notes,
          });
        }
      });
    },
    [user, day]
  );

  const [newNotes, setNewNotes] = useState(null);
  let debouncedNewNotes = useDebounce(newNotes, 1000);

  const onChange = (e) => {
    setNewNotes(e.target.value);
    if (!user) {
      firebase
        .auth()
        .signInAnonymously()
        .catch((error) => console.log(error));
    }
  };

  useEffect(() => {
    // prevent creating empty notes by switching to new page w no note
    if (newNotes === null || debouncedNewNotes === null) {
      return;
    }
    updateNotes(debouncedNewNotes);
  }, [newNotes, debouncedNewNotes, updateNotes]);

  const edited =
    newNotes && newNotes.trim().length > 0 ? " notes-content-edited" : "";
  return (
    <div className="notes">
      <h2>Notes for {convertDayToWord(day)}</h2>
      <div className="notes-container">
        <div className="notes-date">
          <span>{day.format("ddd")}</span>
          <span>{day.format("DD")}</span>
        </div>
        <TextareaAutosize
          rows={4}
          className={`notes-content ${edited}`}
          value={newNotes || ""}
          placeholder={showNotes ? "Write anything..." : ""}
          onChange={onChange}
        />
      </div>
    </div>
  );
}

export default Notes;
