import React, { useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./Dropdown.css";
import useOutsideAlerter from "./OutsideAlerter";

function Dropdown({ element, menu }) {
  // menu format: [{text: ..., icon?: ..., onChange: ...}]
  const [open, setOpen] = useState(false);

  const ref = useRef(null);
  useOutsideAlerter(ref, () => setOpen(false));

  return (
    <>
      <div style={{ position: "relative" }} ref={ref}>
        <span onClick={() => setOpen(!open)}>{element}</span>
        {open && (
          <div className="dropdown">
            <div className="menu">
              {menu.map((m) => (
                <div
                  key={m.text}
                  className="menu-item"
                  onClick={() => {
                    m.onClick();
                    setOpen(false);
                  }}
                >
                  <div className="icon">
                    <FontAwesomeIcon icon={m.icon} style={m.iconStyle} />
                  </div>
                  {m.text}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default Dropdown;
