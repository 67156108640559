import firebase from "firebase";

const firebaseConfig = {
  apiKey: "AIzaSyD20ePs966lmE-TkkClyO039yPd6bhmi2w",
  authDomain: "journal-15cb6.firebaseapp.com",
  databaseURL: "https://journal-15cb6.firebaseio.com",
  projectId: "journal-15cb6",
  storageBucket: "journal-15cb6.appspot.com",
  messagingSenderId: "412198075848",
  appId: "1:412198075848:web:0d2967fef5409adae714fd",
  measurementId: "G-7EXXY5ND3K",
};
if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
  firebase.analytics();
}
export const db = firebase.firestore();
