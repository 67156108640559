import React, { useState, useEffect, forwardRef, useRef } from "react";
import "./Task.css";
import useDebounce from "./debounce";
import TextareaAutosize from "react-autosize-textarea";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faTimes,
  faArrowUp,
  faArrowDown,
  faEllipsisV,
  faArrowRight,
} from "@fortawesome/free-solid-svg-icons";

import { faCircle } from "@fortawesome/free-regular-svg-icons";

import useOutsideAlerter from "./OutsideAlerter";
import Dropdown from "./Dropdown";

function Task(props, ref) {
  const {
    content,
    completed,
    onToggle,
    onChange,
    moveUp,
    moveDown,
    menu,
  } = props;
  const [toggleHover, setToggleHover] = useState(false);
  const [taskHover, setTaskHover] = useState(false);
  const [newContent, setNewContent] = useState(content || "");
  const debouncedNewContent = useDebounce(newContent, 1000);
  const settingsRef = useRef(null);
  useOutsideAlerter(settingsRef, () => setShowDeleteButton(false));
  const [showDeleteButton, setShowDeleteButton] = useState(false);

  // useEffect(() => {
  //   console.log("TASK newContent:" + newContent);
  // }, [newContent]);

  // useEffect(() => {
  //   console.log("TASK onChange " + onChange);
  // }, [onChange]);

  // useEffect(() => {
  //   console.log("TASK debouncedNewContent " + debouncedNewContent);
  // }, [debouncedNewContent]);

  useEffect(() => {
    onChange(debouncedNewContent);
  }, [debouncedNewContent]); // don't put onChange as a dependency or will call trigger when onChange changes due to switching days == writing a bunch of content to taskIds to new collection = error

  // TODO: fix above issue
  // believe it's bc as soon as collection changes, onChange changes as well so above is triggered
  // whereas when collection changes slight delay in retrieving the new tasks so old tasks aren't unmounted yet

  const noBorder = ref ? "no-border" : "";
  useEffect(() => {
    if (ref && ref.current && content.trim() === "") {
      ref.current.focus();
    }
  }, [ref, content]);

  const toggleIcon = completed ? faCheck : toggleHover ? faCheck : faCircle;

  return (
    <div
      className="task"
      onMouseEnter={() => setTaskHover(true)}
      onMouseLeave={() => setTaskHover(false)}
    >
      <div className="main">
        <div className="checkmark-container">
          <div
            className="checkmark"
            onClick={onToggle}
            onMouseEnter={() => setToggleHover(true)}
            onMouseLeave={() => setToggleHover(false)}
          >
            <FontAwesomeIcon icon={toggleIcon} />
          </div>
        </div>
        <div className={`content-container ${noBorder}`}>
          <TextareaAutosize
            className={
              "content" +
              (newContent.trim().length > 0 ? " content-edited" : "")
            }
            value={newContent}
            placeholder="You got this..."
            onChange={(e) => setNewContent(e.target.value)}
            ref={ref}
            style={completed ? { textDecoration: "line-through" } : {}}
          />

          <div className="task-buttons">
            {!completed && (
              <div className="column-buttons up-down">
                <button className="edit-buttons up-down" onClick={moveUp}>
                  <FontAwesomeIcon icon={faArrowUp} />
                </button>
                <button className="edit-buttons up-down" onClick={moveDown}>
                  <FontAwesomeIcon icon={faArrowDown} />
                </button>
              </div>
            )}
            <div className="column-buttons settings" ref={settingsRef}>
              <Dropdown
                element={
                  <button
                    className="edit-buttons settings"
                    // onClick={() => setShowDeleteButton(!showDeleteButton)}
                  >
                    <FontAwesomeIcon icon={faEllipsisV} />
                  </button>
                }
                menu={menu}
              />

              {/* {showDeleteButton && (
                <button className="edit-buttons delete" onClick={onDelete}>
                  <FontAwesomeIcon icon={faTimes} />
                </button>
              )} */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default forwardRef(Task);
