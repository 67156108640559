import React, {
  useContext,
  useRef,
  useState,
  useCallback,
  useEffect,
} from "react";
import { db } from "./firebase";
import firebase from "firebase";
import { UserContext } from "./App";
import Task from "./Task";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faAngleDown,
  faAngleUp,
  faTimes,
  faArrowRight,
} from "@fortawesome/free-solid-svg-icons";
import { faCircle } from "@fortawesome/free-regular-svg-icons";
import moment from "moment";

function Tasks({ tasks, tasksCompleted, collection, day, taskMessage }) {
  const user = useContext(UserContext);
  const collectionRef = (user) =>
    db.collection("users").doc(user.uid).collection(collection);

  const lastTaskRef = useRef(null);

  const [showCompletedTasks, setShowCompletedTasks] = useState(false);

  const onToggle = (taskId, completed) => {
    if (collection === "recurring") {
      const docRef = db
        .collection("users")
        .doc(user.uid)
        .collection(day)
        .doc("recurring-completed");

      docRef.get().then((doc) => {
        if (doc.exists) {
          let completed = false;
          if (doc.data()[taskId]) {
            completed = doc.data()[taskId];
          }

          if (!completed) {
            docRef.update({
              [taskId]: !completed,
              id_list: firebase.firestore.FieldValue.arrayUnion(taskId),
            });
          } else {
            docRef.update({
              [taskId]: !completed,
              id_list: firebase.firestore.FieldValue.arrayRemove(taskId),
            });
          }
        } else {
          docRef.set({ [taskId]: true, id_list: taskId });
        }
      });
    } else {
      collectionRef(user).doc(taskId).update({ completed: !completed });
    }
  };

  const [newAnonTask, setNewAnonTask] = useState(false);
  const [showDummy, setShowDummy] = useState(0);

  useEffect(() => {
    if (newAnonTask && user) {
      setTask("");
    }
  }, [newAnonTask, user]); // adding setTask here breaks bc then triggered every time setTask changes which is every time there's a new collection (switching days)

  const newTask = () => {
    if (!user) {
      setShowDummy(1);
      firebase
        .auth()
        .signInAnonymously()
        .then(() => {
          setNewAnonTask(true);
          console.log("sign in anon successful");
        })
        .catch((error) => console.log(error));
    } else {
      setTask("");
    }
  };

  const setTask = useCallback(
    (content, taskId, tomorrow) => {
      const collectionRef = db
        .collection("users")
        .doc(user.uid)
        .collection(tomorrow ? tomorrow : collection);
      if (taskId) {
        collectionRef
          .doc(taskId)
          .update({ content: content })
          .catch((error) => {
            console.log(
              "ERROR: setTask (updating) for existing task " + taskId
            );
          });
      } else {
        collectionRef
          .add({
            completed: false,
            content: content,
            timestamp: firebase.firestore.Timestamp.fromDate(new Date()),
          })
          .then((taskRef) => {
            const orderRef = collectionRef.doc("order");

            orderRef.get().then((orderDoc) => {
              if (orderDoc.exists) {
                orderRef
                  .update({
                    id_list: firebase.firestore.FieldValue.arrayUnion(
                      taskRef.id
                    ),
                  })
                  .catch((error) => {
                    console.log("ERROR: updating existing order doc");
                  });
              } else {
                orderRef.set({ id_list: [taskRef.id] }).catch((error) => {
                  console.log("ERROR: setting new order doc");
                });
              }

              if (showDummy === 1) {
                setShowDummy(2);
              }
            });
          })
          .catch((error) => {
            console.log("ERROR: adding new task");
          });
      }
    },
    [user, collection, showDummy]
  );

  const taskOnChange = useCallback(
    (taskId) => {
      return (content) => setTask(content, taskId);
    },
    [setTask]
  );

  const deleteTask = (taskId) => {
    collectionRef(user).doc(taskId).delete();
    collectionRef(user)
      .doc("order")
      .update({ id_list: firebase.firestore.FieldValue.arrayRemove(taskId) });
  };

  const moveUp = (taskId) => {
    collectionRef(user)
      .doc("order")
      .get()
      .then((doc) => {
        const id_list = [...doc.data().id_list];
        const i = id_list.indexOf(taskId);
        if (i === 0) return;

        const one_up = id_list[i - 1];
        id_list[i] = one_up;
        id_list[i - 1] = taskId;
        collectionRef(user).doc("order").set({ id_list });
      });
  };

  const moveDown = (taskId) => {
    collectionRef(user)
      .doc("order")
      .get()
      .then((doc) => {
        const id_list = [...doc.data().id_list];
        const i = id_list.indexOf(taskId);
        if (i === tasks.length - 1) return;

        const one_down = id_list[i + 1];
        id_list[i] = one_down;
        id_list[i + 1] = taskId;
        collectionRef(user).doc("order").set({ id_list });
      });
  };

  const moveToTomorrow = (taskId, content) => {
    let tomorrow = moment(day).add(1, "days").format("YYYY-MM-DD");
    // TODO: allow ability to catch error and only delete task if successful move
    setTask(content, null, tomorrow);
    deleteTask(taskId);
  };

  const getMenu = (taskId, content) => {
    const menu = [
      {
        text: "Delete",
        icon: faTimes,
        iconStyle: { color: "rgb(192, 0, 0)" },
        onClick: () => deleteTask(taskId),
      },
    ];
    if (collection !== "persistent-tasks" && collection !== "recurring") {
      menu.unshift({
        text: "Move to next day",
        icon: faArrowRight,
        onClick: () => moveToTomorrow(taskId, content),
      });
    }
    return menu;
  };

  const TaskList = (tasks, completed) => {
    return tasks.map((task, i) => (
      <Task
        key={task.id}
        content={task.content}
        completed={completed}
        onToggle={() => onToggle(task.id, task.completed)}
        onChange={taskOnChange(task.id)}
        // onDelete={() => deleteTask(task.id)}
        moveUp={() => moveUp(task.id)}
        moveDown={() => moveDown(task.id)}
        // moveToTomorrow={() => moveToTomorrow(task.id, task.content)}
        menu={getMenu(task.id, task.content)}
        ref={i === tasks.length - 1 ? lastTaskRef : null}
      />
    ));
  };

  return (
    <div className="tasks-container">
      {showDummy === 1 && <DummyTask />}
      {TaskList(tasks, false)}
      {tasksCompleted.length > 0 && (
        <CompletedButton
          numCompleted={tasksCompleted.length}
          onClick={() => setShowCompletedTasks(!showCompletedTasks)}
          toggleIcon={showCompletedTasks}
        />
      )}
      {showCompletedTasks && TaskList(tasksCompleted, true)}
      <div className="new-task">
        <button className="new-task-button" onClick={newTask}>
          <div className="wrapper">
            <FontAwesomeIcon className="new-task-plus" icon={faPlus} />
            <span className="new-task-text">{taskMessage}</span>
          </div>
        </button>
      </div>
    </div>
  );
}

function DummyTask() {
  return (
    <div className="task">
      <div className="main">
        <div className="checkmark-container">
          <div className="checkmark">
            <FontAwesomeIcon icon={faCircle} />
          </div>
        </div>
        <span style={{ color: "gray", marginTop: "4px" }}>one moment...</span>
      </div>
    </div>
  );
}

function CompletedButton({ numCompleted, onClick, toggleIcon }) {
  return (
    <button className="completed-button" onClick={onClick}>
      <div className="wrapper">
        Completed ({numCompleted}){" "}
        <FontAwesomeIcon
          icon={toggleIcon ? faAngleUp : faAngleDown}
          className="completed-angle"
        />
      </div>
    </button>
  );
}

export default Tasks;
