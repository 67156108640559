import React from "react";

const GoogleIcon = (
  <svg viewBox="0 0 24 24" id="google">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 6.48c1.69 0 2.83.73 3.48 1.34l2.54-2.48C16.46 3.89 14.43 3 12 3 8.48 3 5.44 5.02 3.96 7.96l2.91 2.26C7.6 8.05 9.62 6.48 12 6.48z"
      fill="#EA4335"
    ></path>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.64 12.2c0-.74-.06-1.28-.19-1.84H12v3.34h4.96c-.1.83-.64 2.08-1.84 2.92l2.84 2.2c1.7-1.57 2.68-3.88 2.68-6.62z"
      fill="#4285F4"
    ></path>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.88 13.78a5.54 5.54 0 0 1-.3-1.78c0-.62.11-1.22.29-1.78L3.96 7.96A9.008 9.008 0 0 0 3 12c0 1.45.35 2.82.96 4.04l2.92-2.26z"
      fill="#FBBC05"
    ></path>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 21c2.43 0 4.47-.8 5.96-2.18l-2.84-2.2c-.76.53-1.78.9-3.12.9-2.38 0-4.4-1.57-5.12-3.74l-2.91 2.26C5.45 18.98 8.48 21 12 21z"
      fill="#34A853"
    ></path>
  </svg>
);

export default GoogleIcon;
